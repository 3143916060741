import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { Button, Col, DatePicker, Form, Layout, Row } from 'antd';
import { steps } from './ModelItems';

import { ReactComponent as Picker } from '../../assets/date-picker-icon.svg';
import { ReactComponent as Pluse } from '../../assets/cross-btn-icon.svg';
import { ReactComponent as Complete } from './img/complete-icon.svg';


const { Header } = Layout;
const ModelStepSettings = ({modelSeries, current, setNewDateRange, isNewModel}) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const history = useHistory();
  const { pathname } = useLocation();
  

  useEffect(() => {
    if(modelSeries?.model_start_date && modelSeries?.model_end_date) {
      const date = {
        model_start_date: dayjs(modelSeries.model_start_date),
        model_end_date: dayjs(modelSeries.model_end_date)
      };
      form.setFieldsValue(date);
    }
  }, [modelSeries]);
  
  
  const getPoint = (key) => {
    return steps.find(i => i.key === key)?.value;
  };

  
  const handleCancel = () => {
    history.replace('/quant/model/list');
  };

  const handleContinue = () => {
    history.push(`${pathname}?step=${getPoint(current + 1)}`);
  };

  const onSubmit = (data) => {
    const date = {
      model_start_date: dayjs(data.model_start_date).format('YYYY-MM'),
      model_end_date: dayjs(data.model_end_date).format('YYYY-MM'),
    };
    setNewDateRange(date);
    handleContinue();
  };

  return (
    <>
    <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="text" className="overview-btn">
              {t(isNewModel ? 'NEW_MODEL' : 'UPDATE_MODEL')}
            </Button>
          </Col>
        </Row>
        <div className="top-btn-wrapper">
          <Button 
            type="text" 
            className="portfolio-cancel-btn" 
            icon={<Pluse />}
            iconPosition='end'
            onClick={handleCancel}>
            {t('CANCEL')}
          </Button>
          <Button
            // disabled={!data?.length || !validData}
            icon={<Complete/>}
            iconPosition='end'
            className="portfolio-cancel-btn complete-btn"
            onClick={() => form.submit()}
          >
            {t('CONTINUE')}
          </Button>
/        </div>
      </Header>
      <div style={{ paddingTop: '32px' }}>
        <Form
          form={form}
          name="onboarding-form"
          layout="vertical"
          onFinish={onSubmit}
          className="instrument-form model-settings"
          autoComplete="off">
          <div className={'veryfication-card'}>
            <p className="modal-text modal-result-short">
              {t(isNewModel ? 'NEW_MODEL' : 'UPDATE_MODEL')}
            </p>
            <p className="modal-text modal-result-footer">
              {t('CURRENT_MAP_IS_CHECKED')}
            </p>
          </div>
        <Row justify={'space-between'} align={'middle'} style={{marginTop: '48px'}}>
          <Col className='picker-label' span={12}>
            <span>{t('START')}</span>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-required"
              name={'model_start_date'}
              rules={[{ required: true, message: t('ENTER_DATA') }]}
              required={false}
            >
              <DatePicker
                picker='month'
                placeholder="yyyy-mm"
                format={'YYYY-MM'}
                className="model-datepicker"
                suffixIcon={<Picker />}
                onChange={(value, dateString) => {
                  if (value) {
                    console.log('Selected Date:', dateString);
                    form.setFieldValue('model_start', value );
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'space-between'} align={'middle'} style={{marginTop: '32px'}}>
          <Col className='picker-label' span={12}>
            <span>{t('END')}</span>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-required"
              name={'model_end_date'}
              rules={[{ required: true, message: t('ENTER_DATA') }]}
              required={false}
            >
              <DatePicker
                picker='month'
                placeholder="yyyy-mm"
                format={'YYYY-MM'}
                className="model-datepicker"
                suffixIcon={<Picker />}
                onChange={(value, dateString) => {
                  if (value) {
                    console.log('Selected Date:', dateString);
                    form.setFieldValue('model_end', value);
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        </Form>
      </div>
    </>
  );
};

export default ModelStepSettings;

ModelStepSettings.propTypes = {
  model: PropTypes.object,
  modelSeries: PropTypes.object,
  current: PropTypes.number,
  newDateRange: PropTypes.object,
  setNewDateRange: PropTypes.func,
  isNewModel: PropTypes.bool,
  
};
