/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Row, Col, Modal, Divider, Button, Table, Tooltip } from 'antd';

import { getPortfolioById, saveFullPortfolio } from '../../data/store/portfolio/portfolioActions';
import { getCurrency, getInstrumentsAll } from '../../data/store/instruments/instrumentsActions';

import EditableCellCashes from './EditableCellCashes';
import EditableCellNew from './EditableCellPortfolioNew';
import EditableCellUnlisted from './EditableCellUnlisted';
import FooterHoldings from '../../pages/ClientOverview/FooterHoldings/FooterHoldings';

import { numberWithSpaces, numberWithSpacesInter } from '../../helpers/formatInteger';
import { twoDecimalWithSpaces } from '../../helpers/formatFractional';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import { textSort } from '../../helpers/textSort';

import { ReactComponent as Pluse } from '../../assets/add-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';

import './style.scss';
import CustomSortIcon from '../CustomSortIcon/CustomSortIcon';

export const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

// export const CustomSortIcon = ({ sortOrder }) => {
//   return (
//     <div style={{ display: 'flex', alignItems: 'center', gap: '2px', marginLeft: '8px' }}>
//       <SortUpIcon className={sortOrder === 'ascend' ? 'selected-sort' : ''} />
//       <SortDownIcon className={sortOrder === 'descend' ? 'selected-sort' : ''}/>
//     </div>
//   );
// };

const Portfolio = ({data, dataUnlisted, dataCashes,
  setData, setDataUnlisted, setDataCashes,
  userId,
  portfolioId,
  setNeedSaveHoldings,
  saveAlert,
  setSaveAlert,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('');

  const [tabs , setTabs ] = useState(0);

  const errorDescription = {
    INVALID_ISIN: 'Invalid ISIN',
    INVALID_UNIT: 'Invalid unit',
    INVALID_SHAED_PRICE: 'Invalid shared price',
    INVALID_VALUE: 'Invalid value',
    INVALID_ASSET_CLASS: 'Invalid asset class',
    IMPORT_ERROR_ROW_NOT_FULL: 'Import error. Row not full',
  };
  const [isErrorImportVisible, setIsErrorImportVisible] = useState(false);
  const [importError, setImportError] = useState(null);

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;
  const [editingKeyUnlisted, setEditingKeyUnlisted] = useState('');
  const isEditingUnlisted = (record) => record.key === editingKeyUnlisted;
  const [editingKeyCashes, setEditingKeyCashes] = useState('');
  const isEditingCashes = (record) => record.key === editingKeyCashes;

  const [tableColumns, setTableColumns] = useState([]);
  const [tableColumnsUnlisted, setTableColumnsUnlisted] = useState([]);
  const [tableColumnsCashes, setTableColumnsCashes] = useState([]);

  const { portfolioById, tempInstruments, portfolioById: { is_locked, is_sync_active } } = useSelector(
    (state) => state.portfolioReducer
  );
  const instrumentsList = useSelector(
    (state) => state.instrumentsReducer?.withoutCashes?.list
  );
  const cashesList = useSelector(
    (state) => state.instrumentsReducer.cashes.list
  );
  const { currencyList } = useSelector((state) => state.instrumentsReducer);

  const getCurrencyName = (id) => {
    const name = currencyList?.find(i => i.id === id);
    return name?.name;
  };

  useEffect(() => {
    setSaveAlert({ flag: false, path: '' });
    dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' }));
    dispatch(
      getInstrumentsAll({ limit: -1, offset: 0, type: 'without-cashes' })
    );
    dispatch(getCurrency());
  }, []);

  const getName = (id) => {
    return cashesList.find(i => i.ID === id)?.name;
  };

  const importPortfolio = useCallback(() => {
    const initialValues = tempInstruments?.listed
      ?.filter((i) => !i.error || i?.error?.codes.includes('INVALID_ISIN'))
      ?.map((portfolioRow) => ({
        key: uuidv4(),
        instrument_name: portfolioRow?.data?.name,
        isin: !portfolioRow.error ? portfolioRow?.data?.isin : 'N/A',
        units_number: portfolioRow?.data?.unitsNumber,
        latest_value: portfolioRow?.data?.latest_value,
        latest_value_sek: portfolioRow?.data?.latest_value_sek,
        latest_value_time: portfolioRow?.data?.latest_value_time,
        value: portfolioRow?.data?.value,
        currency_id: 1,
        fee: portfolioRow?.data?.fee,
        asset_class: portfolioRow?.data?.asset_class || 'TBD',
        financial_instrument_id: portfolioRow?.data?.instrument_id,
        financial_asset_id: portfolioRow?.data?.financial_asset_id,
        financial_asset_category_id: portfolioRow?.data?.category_id,
      }));
    setData(initialValues);

    const errorImportListed = tempInstruments?.listed
      ?.filter((i) => i.error && !i.error?.codes.includes('INVALID_ISIN'))
      ?.map((i) => ({
        key: uuidv4(),
        name: i?.data?.name,
        isin: i?.data?.isin,
        errors: i?.error.codes,
      }));

    const initialValuesUnlisted = tempInstruments?.unlisted
      ?.filter((i) => !i.error)
      ?.map((item) => ({
        key: uuidv4(),
        name: item?.data?.name,
        units_number: item?.data?.units_number,
        latest_value_sek: item?.data?.latest_value_sek,
        latest_value_time: item?.data?.latest_value_time,
        value: item?.data?.value,
        financia_asset_id: item?.data?.asset_class_id.toString(),
      }));
    setDataUnlisted(initialValuesUnlisted);

    const errorImportUnlisted = tempInstruments?.unlisted
      ?.filter((i) => i.error)
      ?.map((i) => ({
        key: uuidv4(),
        name: i?.data?.name,
        isin: 'N/A',
        errors: i?.error.codes,
      }));

    const initialValuesCashes = tempInstruments?.cashes
      ?.filter((i) => !i.error)
      ?.map((item) => ({
        key: uuidv4(),
        name: getName(item?.data?.instrument_id),
        instrument_id: item?.data?.instrument_id,
        currency_id: item?.data?.currency_id,
        amount: item?.data?.amount,
      }));
    setDataCashes(initialValuesCashes);

    const errorImportCashes = tempInstruments?.cashes
      ?.filter((i) => i.error)
      ?.map((i) => ({
        key: uuidv4(),
        name: `amount: ${(i?.data?.amount || 0)} currency: ${i?.data?.currency || '?'}`,
        errors: i?.error.codes,
      }));
    if (errorImportListed?.length || errorImportUnlisted?.length || errorImportCashes?.length) {
      setImportError({
        listed: errorImportListed?.length ? errorImportListed : null,
        unlisted: errorImportUnlisted?.length ? errorImportUnlisted : null,
        cashes: errorImportCashes?.length ? errorImportCashes : null,
      });
      setIsErrorImportVisible(true);
    }
  }, [tempInstruments]);

  const getRate = (cashCurency) => {
    const rate = currencyList?.find((i) => i.id === cashCurency)?.rate;
    return userId ? rate : '0';
  };

  const resetInitialValues = useCallback(() => {
    const initialValues = [...portfolioById.portfolio_content].map(
      (portfolioRow) => ({
        key: portfolioRow.id,
        id: portfolioRow.id,
        instrument_name: portfolioRow.instrument_name,
        isin:
          portfolioRow.isin === 'NOT_APPLICABLE' ? 'N/A' : portfolioRow.isin,
        units_number: portfolioRow.units_number || 0,
        latest_value:portfolioRow.latest_value,
        latest_value_sek: Math.round(portfolioRow.latest_value_sek * 100) / 100,
        latest_value_time: portfolioRow?.latest_value_time || '',
        value: portfolioRow.value || 0,
        currency_id: portfolioRow.currency_id,
        fee: portfolioRow.fee,
        asset_class: portfolioRow?.financial_asset?.name || 'TBD',
        financial_instrument_id: portfolioRow?.financial_instrument_id || null,
        financial_asset_id: portfolioRow?.financial_asset_id || null,
        financial_asset_category_id:
          portfolioRow?.financial_asset_category_id || null,
      })
    );
    setData(initialValues?.sort((a, b) => b.value - a.value));
    const getName = (id) => {
      return cashesList.find((i) => i.ID === id)?.name;
    };
    const initialValuesUnlisted = portfolioById?.unlisted_content
      ? [...portfolioById.unlisted_content].map((item) => ({
          key: item.id,
          id: item.id,
          name: item.name,
          units_number: item.units_number || 0,
          latest_value_sek: item.latest_value_sek || 0,
          latest_value_time: item.latest_value_time || '',
          value: item.value || 0,
          financial_asset_id: item?.financial_asset_id.toString(),
          financial_instrument_id: item?.financial_instrument_id,
        }))
      : [];
    setDataUnlisted(initialValuesUnlisted?.sort((a, b) => b.value - a.value));

    const initialValuesCashes = portfolioById?.portfolio_cashes
      ? [...portfolioById.portfolio_cashes].map((item) => ({
          key: item.id,
          id: item.id,
          name: getName(item.instrument_id),
          instrument_id: item.instrument_id,
          currency_id: item.currency_id,
          amount: item.amount,
          totalAmount: Math.round(item.amount * getRate(item.currency_id) * 100) / 100
        }))
      : [];
    setDataCashes(initialValuesCashes?.sort((a, b) => b.amount - a.amount));
  }, [portfolioById, portfolioId, cashesList]);

  useEffect(() => {
    if (
      portfolioById &&
      Object.keys(portfolioById)?.length &&
      portfolioId &&
      !tempInstruments
    ) {
      resetInitialValues();
    } else if (tempInstruments) {
      importPortfolio();
    }
  }, [portfolioById, portfolioId, tempInstruments, cashesList]);

  const getInstrument = (id) => {
    const instrument = instrumentsList.find((i) => i.ID === id);
    console.log('🚀 ~ getInstrument ~ instrument:', instrument);
    return instrument;
  };

  useEffect(() => {
    const columns = [
      {
        title: t('INSTRUMENT'),
        dataIndex: 'instrument_name',
        width: '27%',
        editable: true,
        sorter: (a, b) => textSort(a.instrument_name, b.instrument_name),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('ISIN'),
        dataIndex: 'isin',
        width: '7%',
        sorter: (a, b) => textSort(a.isin, b.isin),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('UNITS'),
        dataIndex: 'units_number',
        align: 'right',
        width: '14%',
        editable: true,
        render: (value) => {
          return numberWithSpacesInter(value);
        },
        sorter: (a, b) => a.units_number - b.units_number,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('SHARE_PRICE'),
        dataIndex: 'latest_value',
        align: 'right',
        width: '13%',
        editable: true,
        render: (value, record) => {
          return value
          ? `${numberWithSpacesInter(value, 4)} ${getCurrencyName(record.currency_id)}`
          : `${numberWithSpacesInter(getInstrument(record?.financial_instrument_id)?.latest_value)} ${getCurrencyName(getInstrument(record?.financial_instrument_id)?.currency_id)}`;
        },
        sorter: (a, b) => a.latest_value - b.latest_value,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('VALUE') +  ' kr',
        dataIndex: 'value',
        align: 'right',
        width: '13%',
        render: (value) => {
          return `${twoDecimalWithSpaces(value)}`;
        },
        sorter: (a, b) => a.value - b.value,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('FEE'),
        dataIndex: 'fee',
        className: 'instrument-fee',
        width: '7%',
        render: (value) => {
          return `${value?.toString()?.replace('.', decimalSeparator())} %`;
        },
        sorter: (a, b) => a.fee - b.fee,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ASSET_CLASS'),
        dataIndex: 'asset_class',
        width: '17%',
        render: (value) => {
          if (value !== 'TBD') {
            return value;
          }
          return '- - -';
        },
        sorter: (a, b) => textSort(a.asset_class, b.asset_class),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: '',
        dataIndex: 'operation',
        align: 'center',
        width: '2%',
        // eslint-disable-next-line react/display-name
        render: (_, record) =>
          data.length >= 1
            ? (
            <Button
              icon={<Delete />}
              disabled={is_sync_active || is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
              type="text"
              onClick={() => handleDelete(record.key)}
            />
              )
            : null,
      },
    ];
    const getAssetClass = (value) => {
      const classObj = {
        16: 'Forest',
        7: 'Private Company',
        8: 'Private Equity/Debt',
        15: 'Real Estate',
      };
      return classObj[value];
    };
    const columnsUnlisted = [
      {
        title: t('INSTRUMENT'),
        dataIndex: 'name',
        width: '30%',
        editable: true,
        sorter: (a, b) => textSort(a.name, b.name),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('UNITS'),
        dataIndex: 'units_number',
        align: 'right',
        width: '15%',
        editable: true,
        render: (value) => {
          return numberWithSpaces(value);
        },
        sorter: (a, b) => a.units_number - b.units_number,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('SHARE_PRICE') + ' kr',
        dataIndex: 'latest_value_sek',
        align: 'right',
        width: '15%',
        editable: true,
        render: (value) => {
          return `${twoDecimalWithSpaces(value)}`;
        },
        sorter: (a, b) => a.latest_value_sek - b.latest_value_sek,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('VALUE') + ' kr',
        dataIndex: 'value',
        align: 'right',
        width: '15%',
        editable: false,
        render: (value) => {
          return `${twoDecimalWithSpaces(value)}`;
        },
        sorter: (a, b) => a.value - b.value,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ASSET_CLASS'),
        dataIndex: 'financial_asset_id',
        width: '23%',
        editable: true,
        render: (value) => {
          return getAssetClass(value);
        },
        sorter: (a, b) => textSort(a.financial_asset_id, b.financial_asset_id),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: '',
        dataIndex: 'operation',
        align: 'center',
        width: '2%',
        // eslint-disable-next-line react/display-name
        render: (_, record) =>
          dataUnlisted.length >= 1
            ? (
            <Button
              disabled={is_sync_active || is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
              icon={<Delete />}
              type="text"
              onClick={() => handleDeleteUnlisted(record.key)}
            />
              )
            : null,
      },
    ];

    const columnsCashes = [
      {
        title: t('CURRENCY'),
        dataIndex: 'name',
        width: '30%',
        editable: true,
        sorter: (a, b) => textSort(a.name, b.name),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('AMOUNT'),
        dataIndex: 'amount',
        width: '15%',
        editable: true,
        render: (value) => {
          return twoDecimalWithSpaces(value);
        },
        sorter: (a, b) => a.amount - b.amount,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('FX_RATE'),
        dataIndex: 'rate',
        width: '15%',
        editable: false,
        render: (_, record) => {
          return record.currency_id ? `${getRate(record.currency_id)} kr` : '';
        },
        sorter: (a, b) => getRate(a.currency_id) - getRate(b.currency_id),
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('AMOUNT') + ' kr',
        dataIndex: 'totalAmount',
        align: 'right',
        width: '15%',
        editable: false,
        render: (value, record) => {
          return `${twoDecimalWithSpaces(record.amount * getRate(record.currency_id))}`;
        },
        sorter: (a, b) => a.totalAmount - b.totalAmount,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ASSET_CLASS'),
        dataIndex: 'financial_asset',
        width: '23%',
        editable: false,
        render: () => {
          return 'Liquidity';
        },
        sorter: (a, b) => textSort(a.financial_asset, b.financial_asset),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: '',
        dataIndex: 'operation',
        align: 'center',
        width: '2%',
        // eslint-disable-next-line react/display-name
        render: (_, record) =>
          dataCashes.length >= 1
            ? (
            <Button
              icon={<Delete />}
              disabled={is_sync_active || is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
              type="text"
              onClick={() => handleDeleteCashes(record.key)}
            />
              )
            : null,
      },
    ];
    setTableColumns(columns);
    setTableColumnsUnlisted(columnsUnlisted);
    setTableColumnsCashes(columnsCashes);
    setNeedSaveHoldings(hasChanges());
  }, [portfolioById, portfolioId, data, dataUnlisted, dataCashes]);

  const handleDelete = (key) => {
    setData(data.filter((item) => item.key !== key));
  };

  const handleDeleteUnlisted = (key) => {
    setDataUnlisted(dataUnlisted.filter((item) => item.key !== key));
  };

  const handleDeleteCashes = (key) => {
    setDataCashes(dataCashes.filter((item) => item.key !== key));
  };

  const handleAdd = () => {
    const newData = {
      key: Date.now(),
      instrument_name: '',
      isin: 'N/A',
      units_number: 0,
      latest_value: 0,
      latest_value_sek: 0,
      value: 0,
      fee: 0,
      asset_class: 'TBD',
      financial_instrument_id: null,
      financial_asset_id: null,
      financial_asset_category_id: null,
    };

    data.length ? setData([...data, newData]) : setData([newData]);
    setEditingKey(newData.key);
  };

  const handleAddUnlisted = () => {
    const newData = {
      key: Date.now(),
      name: '',
      units_number: 0,
      latest_value_sek: 0,
      value: 0,
      financial_asset_id: null,
    };

    dataUnlisted.length
      ? setDataUnlisted([...dataUnlisted, newData])
      : setDataUnlisted([newData]);
    setEditingKeyUnlisted(newData.key);
  };

  const handleAddCashes = () => {
    const newData = {
      key: Date.now(),
      instrument_id: null,
      currency_id: null,
      name: '',
      amount: 0,
      rate: 0,
      totalAmount: 0,
      financia_asset: '',
    };

    dataCashes.length
      ? setDataCashes([...dataCashes, newData])
      : setDataCashes([newData]);
    setEditingKeyCashes(newData.key);
  };

  const handleSave = (row) => {
    console.log('🚀 ~ handleSave ~ row:', row);
    console.log('🚀 ~ handleSave ~ data:', data);
    const newData = data.map((item) => (row.key === item.key ? row : item));
    setData(newData);
  };

  const handleSaveUnlisted = (row) => {
    const newData = dataUnlisted.map((item) =>
      row.key === item.key ? row : item
    );
    setDataUnlisted(newData);
  };

  const handleSaveCashes = (row) => {
    const newData = dataCashes.map((item) =>
      row.key === item.key ? row : item
    );
    setDataCashes(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCellNew,
    },
  };

  const componentsUnlisted = {
    body: {
      row: EditableRow,
      cell: EditableCellUnlisted,
    },
  };
  const componentsCashes = {
    body: {
      row: EditableRow,
      cell: EditableCellCashes,
    },
  };

  const columnsData = tableColumns.map((col) => {
    if (!col?.editable) {
      return {
        ...col,
        onCell: (record) => ({
          decimalSeparator: decimalSeparator(),
          setTarget: () => (!is_sync_active && !is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') && setEditingKey(editingKey === record.key ? '' : record.key),
        }),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        data,
        setTarget: () => (!is_sync_active && !is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') && setEditingKey(editingKey === record.key ? '' : record.key),
        decimalSeparator: decimalSeparator(),
        editing: isEditing(record),
      }),
    };
  });
  const columnsDataUnlisted = tableColumnsUnlisted.map((col) => {
    if (!col?.editable) {
      return {
        ...col,
        onCell: (record) => ({
          setTarget: () => (!is_sync_active && !is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') &&
            setEditingKeyUnlisted(
              editingKeyUnlisted === record.key ? '' : record.key
            ),
        }),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSaveUnlisted,
        data,
        setTarget: () => (!is_sync_active && !is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') &&
          setEditingKeyUnlisted(
            editingKeyUnlisted === record.key ? '' : record.key
          ),
        editing: isEditingUnlisted(record),
      }),
    };
  });
  const columnsDataCashes = tableColumnsCashes.map((col) => {
    if (!col?.editable) {
      return {
        ...col,
        currencyList,
        onCell: (record) => ({
          setTarget: () => (!is_sync_active && !is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') &&
            setEditingKeyCashes(
              editingKeyCashes === record.key ? '' : record.key
            ),
        }),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSaveCashes,
        dataCashes,
        currencyList,
        setTarget: () => (!is_sync_active && !is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') &&
          setEditingKeyCashes(
            editingKeyCashes === record.key ? '' : record.key
          ),
        editing: isEditingCashes(record),
      }),
    };
  });

  const isNeedSaveListed = () => {
    const validListed = data?.filter(
      (i) =>
        !(!i.units_number || !i.latest_value || !i.instrument_name)
    );
    console.log('🚀 ~ isNeedSaveListed ~ validListed:', validListed);
    let isNeed = !!validListed?.length;
    const diffListed = isNeed
      ? validListed.map((i) => {
        const oldInstrument = i?.id
          ? portfolioById?.portfolio_content.find((el) => el.id === i.id)
          : i;
        if (oldInstrument?.id) {
          return (
            i?.financial_instrument_id ===
                oldInstrument.financial_instrument_id &&
              i?.instrument_name === oldInstrument.instrument_name &&
              i?.units_number === oldInstrument.units_number &&
              i?.latest_value ===oldInstrument.latest_value
          );
        } else {
          return false;
        }
      })
      : [true];
    isNeed = !diffListed.every((i) => !!i);
    return isNeed;
  };

  const isNeedSaveUnlisted = () => {
    const validUnlisted = dataUnlisted?.filter(
      (i) => !(!i.units_number || !i.latest_value_sek || !i.name)
    );
    let isNeed = !!validUnlisted?.length;
    const diffListed = isNeed
      ? validUnlisted.map((i) => {
        const oldInstrument = i?.id
          ? portfolioById?.unlisted_content.find((el) => el.id === i.id)
          : i;
        if (oldInstrument?.id) {
          return (
            +i?.financial_asset_id === oldInstrument.financial_asset_id &&
              i?.name === oldInstrument.name &&
              i?.units_number === oldInstrument.units_number &&
              i?.latest_value_sek === oldInstrument.latest_value_sek
          );
        } else {
          return false;
        }
      })
      : [true];
    isNeed = !diffListed.every((i) => !!i);
    return isNeed;
  };

  const isNeedSaveCashes = () => {
    const validCashes = dataCashes?.filter((i) => !(!i.name || !i.amount));
    let isNeed = !!validCashes?.length;
    const diffListed = isNeed
      ? validCashes.map((i) => {
        const oldInstrument = i?.id
          ? portfolioById?.portfolio_cashes.find((el) => el.id === i.id)
          : i;
        if (oldInstrument?.id) {
          return (
            i?.currency_id === oldInstrument.currency_id &&
              i?.amount === oldInstrument.amount
          );
        } else {
          return false;
        }
      })
      : [true];
    isNeed = !diffListed.every((i) => !!i);
    return isNeed;
  };

  function hasChanges () {
    if (
      data?.length < portfolioById?.portfolio_content?.length ||
      dataUnlisted?.length < portfolioById?.unlisted_content?.length ||
      dataCashes?.length < portfolioById?.portfolio_cashes?.length ||
      isNeedSaveListed() ||
      isNeedSaveCashes() ||
      isNeedSaveUnlisted()
    ) {
      return true;
    } else {
      return false;
    }
  }

  const total = useMemo(() => {
    function rate (cashCurency = 1) {
      return currencyList?.find((i) => i.id === cashCurency)?.rate || 0;
    }
    const totalValueCash = dataCashes
      .reduce((acc, cur) => acc + (cur?.amount || 0) * rate(cur?.currency_id), 0);

    const totalValueListed = data
      .reduce((acc, cur) => acc + cur?.value, 0);

    const totalValueUnlisted = dataUnlisted
      .reduce((acc, cur) => acc + cur?.value, 0);

    return { cash: twoDecimalWithSpaces(totalValueCash), listed: twoDecimalWithSpaces(totalValueListed), unlisted: twoDecimalWithSpaces(totalValueUnlisted) };
  }, [data, dataUnlisted, dataCashes, currencyList]);

  const handleTabs = (tab) => {
    setTabs(tab);
  };

  const footerData = useMemo(() => {
      return (
        {return: portfolioById?.return ?? 0, 
          cvar: portfolioById?.cvar ?? 0, 
          vol: portfolioById?.vol ?? 0,
          total: portfolioById?.total_value ?? 0}
      );
  }, [portfolioById]);

  return (
    <>
      <div>
        <Row justify={'space-between'} style={{padding: '24px'}}>
          <Col>
            <Button
              type='text'
              className={`portfolio-tabs-btn ${tabs === 0 ? 'selected' : ''}`}
              onClick={() => handleTabs(0)}
            >
              {t('CASH')}
            </Button>
            <Button
              type='text'
              className={`portfolio-tabs-btn ${tabs === 1 ? 'selected' : ''}`}
              onClick={() => handleTabs(1)}
            >
              {t('LISTED_HOLDINGS')}
            </Button>
            <Button
              type='text'
              className={`portfolio-tabs-btn ${tabs === 2 ? 'selected' : ''}`}
              onClick={() => handleTabs(2)}
            >
              {t('UNLISTED_HOLDINGS')}
            </Button>
          </Col>
        </Row>
        <div style={{ padding: '0' }} className='table-inner-wrapper portfolio-info'>
          {tabs === 0 &&
            <div>
              <Table
                rowClassName={() => 'custom-row'}
                components={componentsCashes}
                rowHoverable={false}
                dataSource={dataCashes}
                columns={columnsDataCashes}
                pagination={false}
              />
              <Row
                style={{ padding: '0 24px 36px' }}
                justify={'end'}
              >
                <Button
                  type='text'
                  disabled={is_sync_active || is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
                  className="holdings-add-btn add-instrument"
                  onClick={handleAddCashes}
                  icon={<Pluse />}
                  iconPosition={'end'}
                >{t('ADD')}</Button>
              </Row>
            </div>
          }
          {tabs === 1 &&
            <div>
              <Table
                rowClassName={() => 'custom-row'}
                components={components}
                rowHoverable={false}
                dataSource={data}
                columns={columnsData}
                pagination={false}
              />
              <Row
                style={{ padding: '0 24px 36px' }}
                justify={'end'}>
                  <Button
                    type='text'
                    className="holdings-add-btn add-instrument"
                    disabled={is_sync_active || is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
                    onClick={handleAdd}
                    icon={<Pluse />}
                    iconPosition={'end'}
                  >{t('ADD')}</Button>
              </Row>
            </div>
          }
          {tabs === 2 &&
            <div>
              <Table
                rowClassName={() => 'custom-row'}
                components={componentsUnlisted}
                rowHoverable={false}
                dataSource={dataUnlisted}
                columns={columnsDataUnlisted}
                pagination={false}
              />
              <Row
                style={{ padding: '0 24px 36px' }}
                justify={'end'}>
                  <Button
                    type='text'
                    className="holdings-add-btn add-instrument"
                    disabled={is_sync_active || is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
                    onClick={handleAddUnlisted}
                    icon={<Pluse />}
                    iconPosition={'end'}
                  >{t('ADD')}</Button>
              </Row>
            </div>
          }
        </div>
      </div>
      <FooterHoldings
        width={'100%'} 
        totalOnly={false}
        data={footerData}
      />
      <Modal
        title={t('FAILED_TO_IMPORT')}
        centered
        open={isErrorImportVisible}
        onCancel={() => setIsErrorImportVisible(false)}
        footer={false}
      >
        {importError?.listed && (
          <h4 className="portfolio-container-title">{t('LISTED_HOLDINGS')}:</h4>
        )}
        {importError &&
          importError?.listed?.map((item, index) => (
            <div key={uuidv4()}>
              <p className="import-error-text">
                {index + 1}) {item.name} - - {item?.isin}
              </p>
              {item?.errors?.map((i) => (
                <p key={uuidv4()} className="import-error-massage">
                  message: {errorDescription[i]}
                </p>
              ))}
            </div>
          ))}
        <Divider />
        {importError?.unlisted && (
          <h4 className="portfolio-container-title">
            {t('UNLISTED_HOLDINGS')}:
          </h4>
        )}
        {importError &&
          importError?.unlisted?.map((item, index) => (
            <div key={uuidv4()}>
              <p className="import-error-text">
                {index + 1}) {item.name} - - {item?.isin}
              </p>
              {item?.errors?.map((i) => (
                <p key={uuidv4()} className="import-error-massage">
                  message: {errorDescription[i]}
                </p>
              ))}
            </div>
          ))}
      </Modal>
      {/* <ModalUnsaveData
        open={modalCancelVisible}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={confirmEditing}
      /> */}
    </>
  );
};

export default Portfolio;

Portfolio.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  setNeedSaveHoldings: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
  data: PropTypes.array,
  dataUnlisted: PropTypes.array,
  dataCashes: PropTypes.array,
  setData: PropTypes.func,
  setDataUnlisted: PropTypes.func,
  setDataCashes: PropTypes.func,
  
};

EditableRow.propTypes = {
  index: PropTypes.number,
};
